
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AsyncThunkConfig, GetThunkAPI} from "@reduxjs/toolkit/dist/createAsyncThunk";
import {config} from "../../config";

// Define a type for the slice state
interface IState {
    store: any;
    storeDetail: any;
    stores: any;
    status: boolean;
    loading: boolean;
    message: any;
}

// Initial state
const initialState: IState = {
    store: null,
    storeDetail: null,
    stores: null,
    status: false,
    loading: false,
    message: null
};

// Async thunks

export const getStoreDetails = createAsyncThunk(
    'store/details',
    async (data: any, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await config.api.createInstance({
                    apiType: 'goSeller',
                    token: data.token
                }).get('/store/get-detail/'+data.userId);
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to fetch';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Slice
export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        clearStoreState: (state:IState) => {
           return initialState
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(getStoreDetails.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(getStoreDetails.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.storeDetail = action.payload.data;
                state.status = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(getStoreDetails.rejected, (state: IState, action: any) => {
                state.loading = false;
                // state.error = action.error.message;
                state.message = action.error.message;
            })

    },
});

export const { clearStoreState } = storeSlice.actions;
export default storeSlice.reducer;
