import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AsyncThunkConfig, GetThunkAPI} from "@reduxjs/toolkit/dist/createAsyncThunk";
import {config} from "../../config";

// Define a type for the slice state
interface IState {
    product: any;
    productDetail: any;
    products: any;
    status: any;
    loading: boolean;
    message: any;
}

// Initial state
const initialState: IState = {
    product: null,
    productDetail: null,
    products: null,
    status: null,
    loading: false,
    message: null
};

// Async thunks

export const createProduct = createAsyncThunk(
    'products/create',
    async (data: any, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await config.api.createInstance({
                apiType: 'goSeller',
                token: data.token,
                contentType: 'multipart/form-data'
            }).post('/product/create', data.product);
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to store';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getAllProducts = createAsyncThunk(
    'products/get-all',
    async (data: any, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await config.api.createInstance({
                apiType: 'goSeller',
                token: data.token
            }).get('/product/get-all/' + data.id);
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to fetch';
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const deleteProduct = createAsyncThunk(
    'products/delete',
    async (data: any, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await config.api.createInstance({
                apiType: 'goSeller',
                token: data.token
            }).get('/product/delete/' + data.id);
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to fetch';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Slice
export const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        clearProductState: (state: IState) => {
            state.product = null;
            state.productDetail = null;
            state.products = null;
            state.loading = false;
            state.status = null;
            state.message = null;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(createProduct.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(createProduct.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.product = action.payload.data;
                state.status = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(createProduct.rejected, (state: IState, action: any) => {
                state.loading = false;
                // state.error = action.error.message;
                state.message = action.payload.message
                state.status = action.payload.status;
            })
            .addCase(getAllProducts.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(getAllProducts.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.products = action.payload.data;
                state.status = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(getAllProducts.rejected, (state: IState, action: any) => {
                state.loading = false;
                // state.error = action.error.message;
                state.message = action.payload.message
                state.status = action.payload.status;
            })
            .addCase(deleteProduct.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(deleteProduct.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.product = action.payload.data;
                state.status = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(deleteProduct.rejected, (state: IState, action: any) => {
                state.loading = false;
                // state.error = action.error.message;
                state.message = action.payload.message
                state.status = action.payload.status;
            })

    },
});

export const {clearProductState} = productSlice.actions;
export default productSlice.reducer;
