import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AsyncThunkConfig, GetThunkAPI} from "@reduxjs/toolkit/dist/createAsyncThunk";
import {config} from "../../config";

// Define a type for the slice state
interface IAuthState {
    user: any;
    token: string;
    isLoggedIn: boolean;
    loading: boolean;
    error: any;
}

// Initial state
const initialState: IAuthState = {
    user: null,
    token: '',
    isLoggedIn: false,
    loading: false,
    error: null,
};

// Async thunks
export const loginUser = createAsyncThunk(
    'auth/login',
    async (userData: any, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await config.api.createInstance({
                apiType: 'accounts',
            }).post('/auth/sign-in', userData);
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to login';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const verifyToken = createAsyncThunk(
    'auth/verify-token',
    async (data: any, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await config.api.createInstance({
                apiType: 'accounts',
                token: data.token
            }).get('/auth/verify-token');
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to login';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const logoutUser = createAsyncThunk(
    'auth/logout',
    async (_, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await config.api.createInstance({
                apiType: 'accounts',
            }).get('/auth/logout');
            return response.status === 200
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to logout';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Slice
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearAuthState: (state: IAuthState) => {
            return initialState
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(loginUser.pending, (state: IAuthState) => {
                state.loading = true;
            })
            .addCase(loginUser.fulfilled, (state: IAuthState, action: any) => {
                state.loading = false;
                state.isLoggedIn = true;
                state.token = action.payload.token;
                state.user = action.payload.data;
            })
            .addCase(loginUser.rejected, (state: IAuthState, action: any) => {
                state.loading = false;
                state.isLoggedIn = false;
                state.error = action.error.message;
            })
            .addCase(verifyToken.pending, (state: IAuthState) => {
                state.loading = true;
            })
            .addCase(verifyToken.fulfilled, (state: IAuthState, action: any) => {
                state.loading = false;
                state.isLoggedIn = true;
                state.user = action.payload.data;
                // state.token = getCookie('token') || ''
            })
            .addCase(verifyToken.rejected, (state: IAuthState, action: any) => {
                state.loading = false;
                state.isLoggedIn = false;
                state.error = action.error.message;
            })
            .addCase(logoutUser.pending, (state: IAuthState) => {
                state.loading = true;
            })
            .addCase(logoutUser.fulfilled, (state: IAuthState) => {
                state.user = null;
                state.token = '';
                state.isLoggedIn = false;
            })
            .addCase(logoutUser.rejected, (state: IAuthState) => {
                state.user = null;
                state.token = '';
                state.isLoggedIn = false;
            });
    },
});

export const {clearAuthState} = authSlice.actions;
export default authSlice.reducer;
