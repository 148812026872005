import axios from 'axios';
import {config} from "./";

interface IOptions {
    token?: string;
    contentType?: any;
    apiType: string
}

function createInstance(options: IOptions) {
    return axios.create({
        baseURL: config.urls[options.apiType].backend,
        headers: {
            'Content-Type': options.contentType ?? 'application/json',
            'Authorization': `Bearer ${options.token}`
        }
    });
}

export {createInstance};
