import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./sass/common.scss";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Providers} from "./providers";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Providers>
        <App/>
    </Providers>
);

reportWebVitals();
