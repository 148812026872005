import CryptoJS from 'crypto-js';
import {config} from "../config";

export const imagePath = (path: string) => {
    if (path) {
        return config.urls.goSeller.backend + '/' + path
    } else {
        return '' //here will be default image url
    }
}

export const getUrlParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const params:any = {};
    queryParams.forEach((value, key) => {
        params[key] = value;
    });
    return params;
}

export const getCookie = (key:string='token') => {
    return document.cookie.split('; ')
        .find(row => row.startsWith(`${key}=`))
        ?.split('=')[1];
}

export const setCooke = (key:string, value:string) => {
    document.cookie = `${key}=${value}; domain=.codencode.ae; path=/; secure; SameSite=None`;
}

export const deleteCookie = (name:string) => {
    document.cookie = `${name}=; domain=.codencode.ae; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; SameSite=None`
}

export const clearAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (let cookie of cookies) {
        let eqPos = cookie.indexOf("="); // Get the index of the "=" character
        let name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie.trim(); // Use slice instead of substr
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.codencode.ae; path=/; secure; SameSite=None"
    }
}

export const encryptData = (data: any) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), config.auth.encryptKey).toString();
};

export const decryptData = (ciphertext: any) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, config.auth.encryptKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    if (/^[[{]/.test(decryptedData)) {
        try {
            return JSON.parse(decryptedData);
        } catch (e) {
            console.error("Decryption resulted in invalid JSON:", e);
        }
    }
    return decryptedData;
};