import React, {useEffect} from 'react';
import {ConfigContext} from '../../customs/contexts';
import { config } from '../../config';
import {useSecureCookie} from "../hooks";

const ConfigProvider = ({ children }: {children: React.ReactNode}) => {
    const [getToken, setToken, removeToken] = useSecureCookie("token", true)
    const token = getToken()

    useEffect(() => {
        // console.log(getToken())
        if (!token || token === 'undefined') {
            window.location.href = config.urls.accounts.frontend+'/sign-in?continue='+encodeURIComponent(window.location.href)+'&service='+config.service
        }
    }, [token]);

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigProvider;
