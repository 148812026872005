import {createInstance} from "./api.config";
import storage from "redux-persist/lib/storage";
import {urlsConfigs} from "./urls.config";

export const config: any = {
    service: 'go-seller',
    layout: {},
    urls: urlsConfigs,
    api: {
        createInstance
    },
    auth: {
        encryptKey: 'ZVxd2owI6EQTiUB8KZQ59caB5QBj2RUo',
    },
    storagePersist: {
        config: {
            key: 'root',
            storage,
            blacklist: []
        }
    },
    cookies: {
        path: '/',
        secure: true,
        domain: '.codencode.ae',
        sameSite: "none"
    }
}