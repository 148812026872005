import {useCookies} from 'react-cookie';
import {decryptData, encryptData} from "../../utils/helper.util";

const useSecureCookie = (
    cookieName: string,
    isSecure: boolean = false
): [() => string | null | any, (data: any, options?: any) => void, (options?: any) => void] => {
    const [cookies, setCookie, removeCookie] = useCookies([cookieName]);

    const setSecureCookie = (data: any, options?: any) => {
        // console.log("get triggered in set")
        try {
            const processedData = isSecure ? encryptData(data) : data;
            setCookie(cookieName, processedData);
        } catch (error) {
            console.error("Error processing data:", error);
        }
    };

    const getSecureCookie = () => {
        // console.log("get triggered in get")
        try {
            const data = cookies[cookieName];
            // console.log(data)
            return data && isSecure ? decryptData(data) : data;
        } catch (error) {
            console.error("Error processing data:", error);
            return null;
        }
    };

    const removeSecureCookie = (options?: any) => {
        // console.log("get triggered in remove")
        removeCookie(cookieName, {...options, path: '/'});
    };

    return [getSecureCookie, setSecureCookie, removeSecureCookie];
};

export default useSecureCookie;